<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row class="mt-3">
                        <!-- App Section Type -->
                        <b-col cols="12" sm="6" md="4">
                            <validation-provider #default="validationContext" name="App Section Type" rules="required">
                                <b-form-group label="App Section Type" label-for="app-section-type" :state="getValidationState(validationContext)">
                                    <v-select v-model="form.app_section_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="appSections" :clearable="false" label="summary" input-id="app-section-type" @option:selected="changeAppSection" @search="filterAppSection" />

                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="4">
                            <validation-provider #default="validationContext" name="Media Type" rules="required">
                                <b-form-group label="Media Type" label-for="media-type" :state="getValidationState(validationContext)">
                                    <v-select v-model="form.featureable_type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :disabled="disableFeatureType" label="Media Type" :options="appSectionTypes" :clearable="false" input-id="media-type" @option:selected="featureTypeChanged()" />
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Featureable list -->
                        <b-col cols="12" sm="6" md="4">
                            <validation-provider #default="validationContext" name="Featureable List" rules="required">
                                <b-form-group :label="featureListLabel" label-for="featureable-list" :state="getValidationState(validationContext)">
                                    <v-select v-model="form.media" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :disabled="!form.featureable_type" :reduce="(val) => val.id" :label="featureListShow" :options="featureList" input-id="featureable-list" @search="searchFeatureableList" />
                                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- link -->
                        <b-col v-if="appSectionType === 'banner'" cols="12" sm="6" md="4">
                            <validation-provider name="placeholder">
                                <b-form-group label="link" label-for="link">
                                    <b-form-input id="link" v-model="form.link" :disabled="form.featureable_type ? false : true" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- imageCropper -->
                        <b-col v-if="loadImage" cols="12">
                            <!-- media -->
                            <b-media no-body>
                                <b-media-body class="mt-75 ml-75">
                                    <b-row>
                                        <b-col cols="12" sm="4">
                                            <!-- upload button -->

                                            <image-cropper v-model="fileForm" label="manager image" :stencil-props="stencilProps" :url.sync="form.thumbnail" />
                                            <!--/ upload button -->
                                        </b-col>
                                    </b-row>
                                    <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                                </b-media-body>
                            </b-media>
                            <!--/ media -->
                        </b-col>
                        <!-- Form Actions -->
                        <b-col cols="12">
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                    {{ specialItemId ? "update" : "add" }}
                                </b-button>
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
    </component>
</template>

<script>
import { BAlert, BButton, BCard, BCardText, BCol, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BImg, BMedia, BMediaBody, BRow, BTab, BTabs } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { email, required } from "@validations";
import { onUnmounted, ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
// repository
import FileRepository from "@/abstraction/repository/fileRepository";
import AppSectionRepository from "@/abstraction/repository/appSectionRepository";
import PlayListRepository from "@/abstraction/repository/playListRepository";
import MusicRepository from "@/abstraction/repository/musicRepository";
import VideoRepository from "@/abstraction/repository/videoRepository";
import NewsRepository from "@/abstraction/repository/newsRepository";
import RadioStationRepository from "@/abstraction/repository/radioStationRepository";
import SpecialItemRepository from "@/abstraction/repository/specialItemRepository";
import debouncedFunction from "@/utils/filter/autocompleteFilter";

// random token
import { makeid } from "@/utils/math";
import ArtistRepository from "@/abstraction/repository/artistRepository";

const appSectionRepository = new AppSectionRepository();
const playListRepository = new PlayListRepository();
const musicRepository = new MusicRepository();
const videoRepository = new VideoRepository();
const newsRepository = new NewsRepository();
const artistRepository = new ArtistRepository();
const radioStationRepository = new RadioStationRepository();
const specialItemRepository = new SpecialItemRepository();
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BFormInput,
        BMedia,
        BFormFile,
        BImg,
        BFormTextarea,
        BFormInvalidFeedback,
        BCardText,
        BButton,
        vSelect,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        required,
        email,
        profileFile: null,
        featureList: [],
        featureListLabel: "feature List",
        featureListShow: "name",
        stencilProps: { aspectRatio: 1, checkImageOrigin: false },
        appSectionTypes: ["music", "video", "radio_station", "playlist", "news", "artist"],
    }),
    setup() {
        const blankForm = {
            placeholder: "",
            app_section_id: null,
            featureable_id: "",
            featureable_type: "",
            file_batch_id: RANDOM_TOKEN,
            link: null,
            thumbnail: null,
        };
        const specialItemId = ref(router.currentRoute.params.id);
        const appSections = ref([]);
        const fileForm = ref({
            batch_id: RANDOM_TOKEN,
            collection_name: "banner",
            model_name: "feature",
            crop: {},
        });
        const loadImage = ref(false);
        const roleOptions = [
            {
                label: "Admin",
                value: "admin",
            },
            {
                label: "Author",
                value: "author",
            },
            {
                label: "Editor",
                value: "editor",
            },
            {
                label: "Maintainer",
                value: "maintainer",
            },
            {
                label: "Subscriber",
                value: "subscriber",
            },
        ];

        const planOptions = [
            {
                label: "Basic",
                value: "basic",
            },
            {
                label: "Company",
                value: "company",
            },
            {
                label: "Enterprise",
                value: "enterprise",
            },
            {
                label: "Team",
                value: "team",
            },
        ];
        const form = ref(JSON.parse(JSON.stringify(blankForm)));

        const USER_APP_STORE_MODULE_NAME = "app-user";

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };

        onMounted(async () => {
            // if (specialItemId.value) {
            //     const data = await specialItemRepository.show(specialItemId.value)
            //     form.value = { ...blankForm, ...data }
            //   console.log(form.value)
            // }
        });
        const onSubmit = async () => {
            console.log("SDA");
            try {
                if (fileForm.value.file && loadImage.value) {
                    const fileRepository = new FileRepository();
                    await fileRepository.store(fileForm.value);
                }
                if (specialItemId.value) {
                    if (typeof form.value.media === "object") {
                        console.log(1);
                        form.value.featureable_id = form.value.media.id;
                    } else {
                        console.log(2);
                        form.value.featureable_id = form.value.media;
                    }
                    await specialItemRepository.update(specialItemId.value, form.value);
                } else {
                    console.log(form.value);
                    form.value.featureable_id = form.value.media;
                    await specialItemRepository.store(form.value);
                }
                router.push({ name: "apps-radio-station-special-item-list" });
            } catch (e) {
                console.log(e);
                return e;
            }
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform);
        return {
            form,
            onSubmit,
            specialItemId,
            fileForm,
            appSections,
            blankForm,
            refFormObserver,
            getValidationState,
            resetForm,
            avatarText,
            roleOptions,
            planOptions,
            loadImage,
        };
    },
    computed: {
        appSectionType() {
            const appSectionSelected = this.appSections.find((x) => x.id === this.form.app_section_id);
            if (appSectionSelected) {
                return appSectionSelected.type;
            }
            return null;
        },
        disableFeatureType() {
            if (this.appSectionType) {
                return !["featured", "latest"].includes(this.appSectionType);
            }
            return true;
        },
    },
    async mounted() {
        await this.loadAppSection();
        await this.showAppSection();
        if (this.specialItemId) {
            this.changeAppSection();
        }
    },
    methods: {
        changeAppSection() {
            let appSection = this.appSections.find((x) => x.id === this.form.app_section_id);
            let appSectionType = appSection ? appSection.type : null;
            if (!this.form.featureable_type) this.form.featureable_type = appSectionType;
            this.loadImage = false;
            if (appSectionType === "featured") {
                this.loadImage = true;
                this.stencilProps.aspectRatio = 1280 / 720;
                console.log(this.form.thumbnail);
                if (!this.specialItemId) this.form.thumbnail = "https://via.placeholder.com/1280x720";
            } else if (appSectionType === "banner") {
                this.loadImage = true;
                this.stencilProps.aspectRatio = 1200 / 400;
                if (!this.specialItemId) this.form.thumbnail = "https://via.placeholder.com/1200x400";
            }
            this.getFeatureData();
        },
        searchFeatureableList(search) {
            debouncedFunction(search, () => {
                this.getFeatureData(search);
            });
        },
        async showAppSection() {
            if (this.specialItemId) {
                const data = await specialItemRepository.show(this.specialItemId);
                this.form = { ...this.blankForm, ...data };
            }
        },
        featureTypeChanged() {
            this.form.media = null;
            this.getFeatureData();
        },
        async getFeatureData(search = null) {
            if (this.form.featureable_type) {
                let params = {};
                switch (this.form.featureable_type) {
                    case "playlist":
                        params = {
                            filters: {
                                slug: {
                                    type: "in",
                                    val: "!(null)",
                                },
                                name: {
                                    type: "like",
                                    val: search,
                                },
                            },
                        };
                        const playListResource = await playListRepository.index(params);
                        this.featureList = playListResource.data;
                        this.featureListShow = "name";
                        this.featureListLabel = "Playlist List";
                        break;
                    case "music":
                        params = {
                            filters: {
                                name: {
                                    type: "like",
                                    val: search,
                                },
                            },
                        };
                        const musicResource = await musicRepository.index(params);
                        this.featureList = musicResource.data;
                        this.featureListShow = "name";
                        this.featureListLabel = "Music List";
                        break;
                    case "artist":
                        params = {
                            filters: {
                                name: {
                                    type: "like",
                                    val: search,
                                },
                            },
                        };
                        const artistResource = await artistRepository.index(params);
                        this.featureList = artistResource.data;
                        this.featureListShow = "name";
                        this.featureListLabel = "Artist List";
                        break;
                    case "video":
                        params = {
                            filters: {
                                name: {
                                    type: "like",
                                    val: search,
                                },
                            },
                        };
                        const videoResource = await videoRepository.index(params);
                        this.featureList = videoResource.data;
                        this.featureListShow = "name";
                        this.featureListLabel = "Video List";
                        break;
                    case "news":
                        params = {
                            filters: {
                                title: {
                                    type: "like",
                                    val: search,
                                },
                            },
                        };
                        const newsResource = await newsRepository.index(params);
                        this.featureList = newsResource.data;
                        this.featureListShow = "title";
                        this.featureListLabel = "News List";
                        break;
                    case "radio_station":
                        params = {
                            filters: {
                                name: {
                                    type: "like",
                                    val: search,
                                },
                            },
                        };
                        const radioStationResource = await radioStationRepository.index(params);
                        this.featureList = radioStationResource.data;
                        this.featureListShow = "name";
                        this.featureListLabel = "Radio Station List";
                        break;

                    default:
                        return "";
                }
            }
        },
        async loadAppSection(options = {}) {
            // this.appSections = await appSectionRepository.index(options);
            // console.log(this.appSections);
            let response = await appSectionRepository.index(options);
            this.appSections = response.filter((item) => {
                return ["MEDIA"].includes(item.summary);
            });
            console.log(this.appSections,"DDD");
        },
        filterAppSection(search) {
            debouncedFunction(search, async () => {
                const filters = {
                    title: {
                        type: "like",
                        val: search,
                    },
                };
                const params = {
                    filters,
                    pagination: {
                        page: 1,
                    },
                };
                this.isLoading = true;
                try {
                    this.loadAppSection(params);
                } catch (err) {
                    console.log(err, "there was an error loading models");
                } finally {
                    this.isLoading = false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
